<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <CCardTitle>Details of Article #{{ article.title }}</CCardTitle>
                <div class="card-header-actions"></div>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="name"
                      label="Name"
                      placeholder="Enter the numbers or name of the article"
                      v-model="article.title"
                      required
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      name="location"
                      label="Location"
                      placeholder="Enter the location of the article"
                      v-model="article.excerpt"
                      required
                    />
                  </CCol>
                  <CCol sm="3" class="article-img">
                    <CImg
                      v-if="article.image"
                      :src="storageURL + article.image"
                      width="200"
                    />
                    <button
                      class="delete-img-btn"
                      @click="deleteImage()"
                      v-if="article.image"
                    >
                      <CIcon size="custom" name="cil-trash" />
                    </button>
                    <p v-if="!article.image">No logo uploaded</p>
                  </CCol>
                  <CCol sm="6">
                    <CInputFile
                      name="image"
                      label="Image"
                      v-on:change="setImage"
                      description="Maximum total upload size is 2MB.
                          Images can be compressed using imagecompressor.com"
                      required
                    />
                  </CCol>

                  <CCol sm="12" class="mt-4">
                    <ckeditor
                      :editor="editor"
                      v-model="article.content"
                      :config="editorConfig"
                    ></ckeditor>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CButton
              variant="outline"
              type="submit"
              color="primary"
              @click="updateArticle"
            >
              <CIcon name="cil-check-circle" />
              Update
            </CButton>
            <CButton
              class="float-right"
              variant="outline"
              type="submit"
              color="danger"
              @click="deleteArticle"
            >
              <CIcon name="cil-trash" /> Delete
            </CButton>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import ArticleAPI from "/app/src/api/article.js";
import LocationAPI from "/app/src/api/location.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Detail",
  data: () => {
    return {
      article: {},
      newImage: {},
      storageURL: process.env.VUE_APP_STORAGE_URL,
      articleAPI: new ArticleAPI(),
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "bold",
          "italic",
          "|",
          "link",
          "blockquote",
          "inserttable",
          "|",
          "bulletedlist",
          "numberedlist",
          "|",
          "undo",
          "redo",
        ],
      },
    };
  },
  created: function () {
    this.getArticle(this.$route.params.id);
  },
  methods: {
    getArticle: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.articleAPI
        .detail(id)
        .then((article) => {
          this.$store.dispatch("stopLoading");
          self.article = article;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    validateSubmission: function () {
      if (this.article.title.length < 1) return "Title is required";
      if (this.article.excerpt.length < 1) return "Excerpt is required";
      if (this.article.content.length < 1) return "Content is required";
      if (this.newImage && this.newImage.size > 1200000)
        return "Image is too large. Max. 1MB";
      return true;
    },
    updateArticle: function (id) {
      console.log(this.article);
      let message = this.validateSubmission();
      if (message !== true) {
        this.$store.dispatch("stopLoading");
        this.$alert.show({ type: "danger", message: message });
        return;
      }

      this.$store.dispatch("loading");

      let self = this;
      this.articleAPI
        .update(this.article, this.newImage)
        .then((article) => {
          this.article = article;
          this.$store.dispatch("stopLoading");
          this.$alert.show({
            type: "success",
            message: "Project has been updated",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    deleteArticle: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.articleAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              console.log(result);
              this.$router.push({ path: "/manager/articles" });
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading");
              console.log(error);
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },
    setImage: function (files, e) {
      this.newImage = files[0];
      console.log(this.newImage);
    },
    deleteImage: function () {
      this.article.image = "";
    },
  },
};
</script>

<style scoped>
.article-image img {
  width: 100%;
}

.delete-img-btn {
  position: absolute;
  top: -10px;
  right: 5px;
  z-index: 999;
  color: white;
  background-color: red;
  padding: 5px;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  border: none;
}

.delete-img-btn svg {
  margin-top: -9px;
}
</style>
